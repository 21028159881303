// Background Mixins //
@each $color, $value in $theme-gradient-colors {
	.bg-gradient-#{$color} {
		background: theme-gradient-color($color);
	}
}
.App-header {
	background-color: #232741;
	// margin-left: -400px;
	height: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// font-size: calc(10px + 2vmin);
	color: white;
  }
  